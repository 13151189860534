import React from "react";
import {HttpError, useShow} from "@pankod/refine-core";
import {Controller, useForm, UseModalFormReturnType,} from "@pankod/refine-react-hook-form";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Drawer,
    Edit,
    FormControl,
    FormLabel,
    Stack,
    TextField,
    Typography
} from "@pankod/refine-mui";
import AddBoxIcon from '@mui/icons-material/AddBox';
import axios from "axios";
import SimpleHTMLEditor from "./htmlEditor";

export const EditBlog = React.memo(({editDrawerFormProps, blog}: {
    editDrawerFormProps:
        UseModalFormReturnType<any>; blog: any;
}) => {
    const {
        modal: {visible, close},
    } = editDrawerFormProps;
    const {
        watch,
        refineCore: {formLoading},
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm();
    const [inputs, setInputs] = React.useState<any>()

    const imageInput = watch("images");

    const onSubmit = async (data: any) => {
        const formData = new FormData();
        formData.append("slug", data?.slug ?? blog?.slug);
        formData.append("image", data?.image?.[0] ?? JSON.stringify(blog?.image));
        formData.append("title", data?.title ?? blog?.title);
        formData.append("description", inputs ?? blog?.description);
        await axios.patch(`https://api.creonajans.com/api/blogs/${blog?._id}`,
            formData
        ).then(function (response) {
            close();
        })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <Drawer
            sx={{zIndex: "1301"}}
            PaperProps={{
                sx: {
                    width: {sm: "100%", md: "50%"},
                    height: '100%',
                    overflow: 'scroll',
                    '& .MuiPaper-root': {overflow: 'scroll'}
                }
            }}
            open={visible}
            onClose={close}
            anchor="right"
        >

            <Edit
                resource="blogs"
                footerButtonProps={{sx: {display: 'none'}}}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <Box
                            component="form"
                            sx={{display: "flex", flexDirection: "column"}}
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >


                            <FormControl>
                                <FormLabel>
                                    Resim
                                </FormLabel>
                                <Stack
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="1px dashed  "
                                    borderColor="primary.main"
                                    borderRadius="5px"
                                    padding="50px"
                                    marginTop="5px"
                                >
                                    <label htmlFor="image">
                                        <input
                                            id="file"
                                            {...register("image")}
                                            type="file"
                                            name="image"
                                        />
                                        <Avatar
                                            sx={{
                                                cursor: "pointer",
                                                width: {
                                                    xs: 100,
                                                    md: 140,
                                                },
                                                height: {
                                                    xs: 100,
                                                    md: 140,
                                                },
                                            }}
                                            src={`https://api.creonajans.com/uploads/${blog?.image?.filename}_resized.webp`}
                                            alt="Store Location"
                                        />
                                    </label>

                                </Stack>

                            </FormControl>

                            <TextField
                                {...register("slug")}
                                defaultValue={blog?.slug}
                                margin="normal"
                                fullWidth

                                InputLabelProps={{shrink: true}}
                                type="text"
                                label="Slug"
                                name="slug"
                            />

                            <TextField
                                {...register("title")}
                                defaultValue={blog?.title}
                                margin="normal"
                                fullWidth

                                InputLabelProps={{shrink: true}}
                                type="text"
                                label="Başlık"
                                name="title"
                            />

                            <SimpleHTMLEditor htmlContent={inputs} setHtmlContent={setInputs} defaultVal={blog?.description} />

                         
                            <Box display="flex" justifyContent="end">

                                <Button type="submit" color="success" variant="contained">Oluştur</Button>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Edit>
        </Drawer>
    );
});
