import React, { useState, useEffect, useContext } from "react";
import {
    AppBar,
    IconButton,
    Avatar,
    Typography,
    Toolbar,
    Stack,
} from "@pankod/refine-mui";
import {
    useGetIdentity,
} from "@pankod/refine-core";
import {
    DarkModeOutlined,
    LightModeOutlined,
} from "@mui/icons-material";

import { ColorModeContext } from "contexts";

interface IOptions {
    label: string;
    url: string;
    link: string;
    category: string;
}

export const Header: React.FC = () => {
    const [value, setValue] = useState("");
    const [options, setOptions] = useState<IOptions[]>([]);

    const { mode, setMode } = useContext(ColorModeContext);

    const { data: user } = useGetIdentity();

    useEffect(() => {
        setOptions([]);
    }, [value]);

    return (
        <AppBar color="default" sx={{background: mode === 'dark' ? '#160726' : '#1A636C'}} position="sticky" elevation={1}>
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <Stack direction="row" alignItems="center" justifyContent="end" width="100%">
                        <IconButton
                            onClick={() => {
                                setMode();
                            }}
                        >
                            {mode === "dark" ? (
                                <LightModeOutlined />
                            ) : (
                                <DarkModeOutlined />
                            )}
                        </IconButton>
                        <Stack
                            direction="row"
                            gap="4px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2" sx={{color: '#fff', mr:1}}>
                                {user?.name}
                            </Typography>
                            <Avatar src={user?.avatar} alt={user?.name} />
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
