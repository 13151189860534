import React from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {DataGrid,  DateField,  GridActionsCellItem, GridColumns, List, Tooltip, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Box } from "@mui/system";

export const MessageList: React.FC<IResourceComponentsProps> = () => {
    const {dataGridProps, tableQueryResult} = useDataGrid<any>();
    const messageData = tableQueryResult?.data?.data
    // @ts-ignore
    const messages = messageData?.data ?? []

    const columns = React.useMemo<GridColumns<any>>(
      () => [
          {
              field: "createdDate",
              flex: 2,
              headerName: "Tarih",
              minWidth: 150,
              renderCell: (params: any) => {
                  const tarih = new Date(params.value); // Örnek bir tarih ve saat
                  const dakika = tarih.getMinutes();
                  return (
                      <>
                          <Box display="flex" sx={{fontSize: 14}}>
                              <DateField format="DD/MM/YYYY HH" value={params.value} />
                              : {dakika} 
                          </Box>
                      </>
                  );
                }
          },
          {
              field: "follower",
              flex: 2,
              headerName: "Takipçi",
              width: 100,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "date",
              flex: 2,
              headerName: "Doğum Tarihi",
              width: 150,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "username",
              flex: 2,
              headerName: "Kullanıcı Adı",
              width: 150,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "name",
              flex: 2,
              headerName: "İsim",
              width: 100,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "platform",
              flex: 2,
              headerName: "Platform",
              width: 100
          },
          {
              field: "phone_number",
              flex: 2,
              headerName: "Telefon Numarası",
              width: 150,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "email",
              flex: 2,
              headerName: "E-mail",
              width: 150,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
          {
              field: "description",
              flex: 2,
              headerName: "Mesaj",
              width: 500,
              renderCell: (params: any) => {
                  return (
                    <Tooltip title={params?.value}>
                      <span> {params?.value} </span>
                    </Tooltip>
                  );
                }
          },
      ],
      [],
  );

    return (
        <>
            <List title={<Typography variant="h4">Gelen Mesajlar</Typography>}>
                <DataGrid {...dataGridProps} rows={messages ?? []} getRowId={(row) => row?._id} columns={columns} autoHeight sx={{ '& .MuiDataGrid-columnHeaders': {background: '', color: '#fff' } }} />
            </List>

        </>
    )
}
