import React from "react";
import {Box, Button, DataGrid, DateField, Divider, Grid, GridColumns, List, Tooltip, Typography, useDataGrid} from "@pankod/refine-mui";
import {useList} from "@pankod/refine-core";

export const DashboardPage: React.FC = () => {
    const {data} = useList({resource: "subscriptions"})
    // @ts-ignore
    const emails = data?.data?.data;
    const {dataGridProps, tableQueryResult} = useDataGrid<any>({resource: 'messages'});
    const messageData = tableQueryResult?.data?.data
    // @ts-ignore
    const messages = messageData?.data ?? []


    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "createdDate",
                flex: 2,
                headerName: "Tarih",
                minWidth: 150,
                renderCell: (params: any) => {
                    const tarih = new Date(params.value); // Örnek bir tarih ve saat
                    const dakika = tarih.getMinutes();
                    return (
                        <>
                            <Box display="flex" sx={{fontSize: 14}}>
                                <DateField format="DD/MM/YYYY HH" value={params.value} />
                                : {dakika} 
                            </Box>
                        </>
                    );
                  }
            },
            {
                field: "follower",
                flex: 2,
                headerName: "Takipçi",
                width: 100,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "date",
                flex: 2,
                headerName: "Doğum Tarihi",
                width: 150,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "username",
                flex: 2,
                headerName: "Kullanıcı Adı",
                width: 150,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "name",
                flex: 2,
                headerName: "İsim",
                width: 100,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "platform",
                flex: 2,
                headerName: "Platform",
                width: 100
            },
            {
                field: "phone_number",
                flex: 2,
                headerName: "Telefon Numarası",
                width: 150,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "email",
                flex: 2,
                headerName: "E-mail",
                width: 150,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "description",
                flex: 2,
                headerName: "Mesaj",
                width: 500,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
        ],
        [],
    );
    return (
        <>
            <h1>Yönetim Paneline Hoşgeldiniz.</h1>
            <Grid container width="100%" rowSpacing={2} columnSpacing={2} xs={12}>
                <Grid item xs={12}>
                    <List title={<Typography variant="h4">Influencer'lardan Gelen Mesajlar</Typography>}>
                        <DataGrid {...dataGridProps} rows={messages ?? []} getRowId={(row) => row?._id} columns={columns} autoHeight rowCount={5} />
                    </List>
                </Grid> 
                <Grid item xs={12}>
                    <List title={<Typography  fontWeight={600} fontSize={20} variant="h4">Abone Olanlar</Typography>}>
                        <Box>
                            {
                                emails?.map((email: any) => {
                                const tarih = new Date(email.createdDate); // Örnek bir tarih ve saat
                                const dakika = tarih.getMinutes();
                                return <><Box display="flex" alignItems="center" justifyContent="space-between" p={0.5}>
                                <Box display="flex" gap={2} alignItems="center">
                                    <Box display="flex" sx={{fontSize: 14}}>
                                    <DateField format="DD/MM/YYYY HH" value={email?.createdDate} />
                                    : {dakika} 
                                    </Box>
                                    <Divider light sx={{width: '2px', height: '100%'}} />
                                    <Typography>  {email?.email}</Typography>
                                  </Box>
                                  <Button color="success" variant="contained" component="a" href={"mailto:" + email?.email}>Geri Dönüş Yap.</Button>
                                </Box>
                                    <Divider sx={{width: '100%', backgroundColor: '#999999', mt: 1.5}}/>

                                </>})
                            }
                        </Box>
                    </List>
                </Grid> 
            </Grid>
        </>
    );
};