import React from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {DataGrid,  GridActionsCellItem, GridColumns, List, Tooltip, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

export const StoreFormList: React.FC<IResourceComponentsProps> = () => {
    const {dataGridProps, tableQueryResult} = useDataGrid<any>();
    const messageData = tableQueryResult?.data?.data
    // @ts-ignore
    const messages = messageData?.data ?? []

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "store",
                flex: 2,
                headerName: "Marka",
                width: 150
            },
            {
                field: "email",
                flex: 2,
                headerName: "E-mail",
                width: 150,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "description",
                flex: 2,
                headerName: "Mesaj",
                width: 500,
                renderCell: (params: any) => {
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
        ],
        [],
    );

    return (
        <>
            <List title={<Typography variant="h4">Gelen Mesajlar</Typography>}>
                <DataGrid {...dataGridProps} rows={messages ?? []} getRowId={(row) => row?._id} columns={columns} autoHeight />
            </List>

        </>
    )
}
