import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Grid, Select, MenuItem } from '@mui/material';

function SimpleHTMLEditor({htmlContent, setHtmlContent, defaultVal}: {htmlContent: string; setHtmlContent: any; defaultVal: string}) {

  const handleAddHeading = () => {
    setHtmlContent(htmlContent + '<h1>Başlık</h1>');
  };

  const handleAddSpace = () => {
    setHtmlContent(htmlContent + '<br>');
  };

  const handleAddText = () => {
    setHtmlContent(htmlContent + '<p>Paragraf</p>');
  };

  return (
    <>
      <Typography sx={{fontSize: 16, mt:4}} gutterBottom>
        Açıklama
      </Typography>
      <Paper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddHeading}
            >
              Başlık Ekle
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSpace}
            >
              Boşluk Bırak
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddText}
            >
              Paragraf ekle
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="HTML İçeriği"
              variant="outlined"
              fullWidth
              multiline
              defaultValue={defaultVal ?? ''}
              rows={10}
              value={htmlContent}
              onChange={(e) => setHtmlContent(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h2" gutterBottom>
              Önizleme:
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default SimpleHTMLEditor;