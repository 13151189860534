import React, { useEffect } from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {Button, CreateButton, DataGrid,  DeleteButton,  Grid,  GridActionsCellItem, GridColumns, IconButton, List, Paper, Popover, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close, MoreVertSharp } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import { useModalForm } from "@pankod/refine-react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { EditBlog } from "components/edit";

export const BlogList: React.FC<IResourceComponentsProps> = () => {
    const { mutate: mutateDelte } = useDelete();
    const [gallery, setGallery] = React.useState<any>(null);
    const [isOpenId, setIsOpenId] = React.useState<any>({});
    const editDrawerFormProps = useModalForm<any>({
        refineCoreProps: { action: "edit" },
    });

    const {
        modal: { show: showEditDrawer },
    } = editDrawerFormProps;

    useEffect(() => {
        fetch('https://api.creonajans.com/api/blogs').then((res) => res.json()).then((data) => {
            setGallery(data?.data)
        });
    }, [])
    
    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item xs={12} md={12}>
                    <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            padding={1}
                            direction="row"
                            gap={2}
                        >
                            <Typography variant="h5">
                                Blog
                            </Typography>
                            <CreateButton
                                variant="outlined"
                                sx={{ marginBottom: "5px" }}
                            >
                                Blog Ekle
                            </CreateButton>
                    </Stack>
                </Grid>
                {
                    gallery?.map((item: any, key: number) => 
                        <Grid key={key} item xs={12} md={5.9} lg={2.9}>
                            <Stack spacing={2} sx={{border: '1px solid #333333', p:1}}>
                            <Box component="img" src={'https://api.creonajans.com/uploads/' + item?.image?.filename + '_resized.webp'} height={200} width="auto" />
                            
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" component="div">
                                    <Typography align="center">{item.title}</Typography>
                                  
                                </Box>
                                <Typography align="left" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', maxHeight: 100 }}>{item.description}</Typography>
                                <Box display="flex" width="100%" justifyContent="space-between"
                                    >
                                         <Button
                                            onClick={() => {
                                                setIsOpenId({});
                                                showEditDrawer();
                                                setIsOpenId(item)
                                            }}
                                            size="small"
                                            startIcon={<EditIcon />}
                                        >
                                            Düzenle
                                        </Button>
                                        <DeleteButton size="small" recordItemId={item?._id} sx={{padding: "5px 10px", width: '100%'}} >
                                        </DeleteButton>
                                    </Box>
                            </Stack>
                        <EditBlog editDrawerFormProps={editDrawerFormProps} blog={isOpenId} />
                        </Grid>
                    )
                }
            </Grid>

        </>
    )
}
