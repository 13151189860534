import { Refine } from "@pankod/refine-core";
import { KBarProvider } from "@pankod/refine-kbar";
import {
    ErrorComponent,
    ReadyPage,
    Layout,
    GlobalStyles,
    CssBaseline,
    RefineSnackbarProvider, responsiveFontSizes, createTheme, ThemeProvider,
} from "@pankod/refine-mui";
import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { authProvider } from "authProvider";
import { DashboardPage } from "pages/dashboard";
import { AuthPage } from "pages/auth";
import { ColorModeContextProvider } from "contexts";
import { Header, Title } from "components";
import React from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { MessageList } from "pages/messages";
import { BlogList } from "pages/blog";
import { BlogCreate } from "pages/blog/create";
import CollectionsIcon from '@mui/icons-material/Collections';
import { StoreFormList } from "pages/storeform";

const App: React.FC = () => {
    const { t, i18n } = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    // @ts-ignore
    let customTheme = createTheme({
        palette: {
            primary: {
                main: "#330f49",
            },
            secondary: {
                main: "#45d0c8",
            },
        },
    });

    customTheme = responsiveFontSizes(customTheme);

    console.log(process.env.PUBLIC_URL)

    return (
        <ThemeProvider theme={customTheme}>
        <KBarProvider>
            <ColorModeContextProvider>
                <CssBaseline />
                <GlobalStyles
                    styles={{ html: { WebkitFontSmoothing: "auto" } }}
                />
                <RefineSnackbarProvider>
                    <Refine
                        routerProvider={{
                            ...routerProvider,

                        }}
                        dataProvider={dataProvider(
                            'https://api.creonajans.com/api',
                        )}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        DashboardPage={DashboardPage}
                        Title={Title}
                        Layout={Layout}
                        Header={Header}
                        LoginPage={() => (
                            <AuthPage
                                type="login"
                                formProps={{
                                    defaultValues: {
                                        email: "admin@gmail.com",
                                        password: "editor",
                                    },
                                }}
                            />
                        )}
                        catchAll={<ErrorComponent />}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        resources={[
                            {
                                name: "blogs",
                                list: BlogList,
                                create: BlogCreate,
                                icon: <CollectionsIcon />,
                                options: {
                                    label: "Blog"
                                }
                            },
                            {
                                name: "messages",
                                list: MessageList,
                                icon: <QuestionAnswerIcon />,
                                options: {
                                    label: "Influencer Mesajları"
                                }
                            },
                            {
                                name: "store-form",
                                list: StoreFormList,
                                icon: <QuestionAnswerIcon />,
                                options: {
                                    label: "Marka Mesajları"
                                }
                            },

                        ]}
                    />
                </RefineSnackbarProvider>
            </ColorModeContextProvider>
        </KBarProvider>
        </ThemeProvider>
    );
};

export default App;
